import { useEffect, useState } from 'react';
import axios from 'axios';

import AdoptionCard from './adoptionCard';
import AdoptionStatic from '../adoption-process-static/adoptionStatic';
import Loading from '../loading';

import './css/adoptionList.css';

export default function AdoptionList({adoptionAlbumId}) {
    const [adoptionList, setAdoptionList] = useState([]);
    // Get adoptionAlbumId elements
    useEffect(() => {
        axios.post(
            'https://www.acpacesena.org/api/v1/album',
            {
                "albumId": `${adoptionAlbumId}`
            }
        ).then((response) => {
            if (Array.isArray(response.data.data)) {
                setAdoptionList(response.data.data);
            } else {
                console.error("Response data is not an array");
            }
        }).catch((error) => {
            console.error(error);
        });
    }, [adoptionAlbumId]);

    return (
        <div>
            <div className="headline">
                <div className="title">Adotta</div>
            </div>
            {adoptionList.length === 0 && <Loading />}
            <div className="adoption-list">
                {adoptionList.length > 0 && adoptionList.map((album) => (
                    <AdoptionCard key={album.id} images={album.images} url={album.link} description={album.name} />
                ))}
            </div>
            <AdoptionStatic />
        </div>
    )
}