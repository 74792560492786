import axios from 'axios';
import { useState } from 'react';


export default function Cookies() {
    axios.get('https://www.iubenda.com/api/privacy-policy/64765399/cookie-policy').then((response) => {
        setData(response.data.content);
    })

    const [data, setData] = useState(null);

    return (
        <div className='legal'>
            <div className="headline">
                <div className="title">Cookies</div>
            </div>
            <div className="legal-content" style={{
                padding: '1em',
                color: 'var(--primary-red)',
                fontFamily: 'var(--secondary-font)'
                }} dangerouslySetInnerHTML={{__html: data}}>
            </div>
        </div>
    );

}