import {useState} from 'react';
import './css/card-about-us.css';

export default function AboutUsCard({image, title, description, buttonText, buttonUrl}) {

    const [status, setStatus] = useState("closed");

    function toggleDescription() {
        if (status === "closed") {
            setStatus("open");
        } else {
            setStatus("closed");
        }
    }

    return (
        <div className="card-about-us" style={{backgroundImage: `url("${image}")`}}>
            <div className={`card-wrapper ${status}`}>
                <div className="card-title" onClick={toggleDescription}>{title}</div>
                <div className={`description ${status} body-large`} onClick={toggleDescription}>
                    {description}
                </div>
                <div className={`button-wrapper ${status}`}>
                    <a href={buttonUrl}>{buttonText}</a>
                </div>
            </div>
        </div>
    )
}