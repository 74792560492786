import { Link } from "react-router-dom";
import logo from "../img/logo/main-red/rosa-orizzontale.png";

import hamburger from "../img/ham-menu.svg";
import close from "../img/ham-close.svg";

export default function Header() {

    function toggleMenu() {
        // Change the .active class in .hamburger-menu > img
        const hamburgerMenuImgs = document.querySelectorAll(".hamburger-menu img");
        for (let image of hamburgerMenuImgs) {
            image.classList.toggle("active");
        }

        // Toggle the .active class in .top-menu
        const topMenu = document.querySelector(".top-menu");
        topMenu.classList.toggle("active");
    }

    return (
        <header>
            <div className="logo"><a href="/"><img src={logo} /></a></div>
            <div className="hamburger-menu" onClick={toggleMenu}>
                <img className="open active" src={hamburger}/>
                <img className="close" src={close}/>
            </div>
            <div className="top-menu">
                <div className="link"><Link to="/adoptions">Adozioni</Link></div>
                <div className="link"><Link to="/advice">Consigli</Link></div>
                <div className="link"><Link to="/events">Eventi</Link></div>
                <div className="link"><Link to="/who-we-are">Associazione</Link></div>
                <div className="link"><Link to="/contact">Contatti</Link></div>
                <div className="link"><Link to="/donate">Sostienici</Link></div>
            </div>
        </header>
    )
}