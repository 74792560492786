import './css/advice.css';

import imgAdulto from './img/adulto.jpg';
import imgCapodano from './img/capodano.jpg';
import lavarlo from './img/lavarlo.jpg';
import primaverilli from './img/primaverilli.jpg';


export default function Advice() {
    return (
        <div className="advice">
            <div className="headline">
                <div className="title">Consigli</div>
            </div>
            <div className="card-wrapper">
                <div className="card-blog">
                    <div className="image" style={{backgroundImage: `url(${imgAdulto})`}}></div>
                    <div className="detail">
                        <div className="title-small">Perche` adottare un cane adulto</div>
                        <div className="button-wrapper">
                            <a className="link-dark-default" href="#">leggi di piu</a>
                        </div>
                    </div>
                </div>
                <div className="card-blog">
                    <div className="image" style={{backgroundImage: `url(${imgCapodano})`}}></div>
                    <div className="detail">
                        <div className="title-small">Quando e come lavarlo</div>
                        <div className="button-wrapper">
                            <a className="link-dark-default" href="#">leggi di piu</a>
                        </div>
                    </div>
                </div>
                <div className="card-blog">
                    <div className="image" style={{backgroundImage: `url(${lavarlo})`}}></div>
                    <div className="detail">
                        <div className="title-small">Cosa fare con i botti di Capodanno</div>
                        <div className="button-wrapper">
                            <a className="link-dark-default" href="#">leggi di piu</a>
                        </div>
                    </div>
                </div>
                <div className="card-blog">
                    <div className="image" style={{backgroundImage: `url(${primaverilli})`}}></div>
                    <div className="detail">
                        <div className="title-small">Attenzione alle passeggiate primaverili</div>
                        <div className="button-wrapper">
                            <a className="link-dark-default" href="#">leggi di piu</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}