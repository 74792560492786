import { useState } from 'react';

import './css/card-adoption.css';

export default function AdoptionCard({images, url, description}) {
    const [status, setStatus] = useState("closed");
    function displayDescription() {
        if (status === "closed") {
            setStatus("open");
        } else {
            setStatus("closed");
        }
    }
    return (
        <div className="card-adoption" style={{backgroundImage: `url("${images[0].source}")`}}>
            <div className={`card-wrapper ${status}`}>
                <div className="card-title" onClick={displayDescription}>Descrizione</div>
                <div className={`description ${status}`}>{description}</div>
                <div className={`button-wrapper ${status}`}>
                    <div className="button-link link-dark-default" onClick={displayDescription}>Chiudi</div>
                    <div className="button-link link-light-default"><a href={url}>Leggi di più</a></div>
                </div>
            </div>
        </div>
    )
}