import img5x1000 from "./img/5x1000.jpg";
import imgCanile from "./img/canile.jpg";
import imgLibera from "./img/libera.jpg";

import './css/donate.css';

export default function Donate() {

    return (
        <div className="donate">
            <div className="headline">
                <div className="title">Sostienici</div>
            </div>
            <div className="section-wrapper">
                <div className="attributes-wrapper">
                    <div className="text-image-section">
                        <div className="headline">
                            <div className="title-small">Dona il tuo 5x1000</div>
                            <div className="subtitle">Nella dichiarazione dei redditi sounta la casella “Sostegno agli enti del terzo settore” ed inserisci il codice dell’associazione ACPA: <br /><span style={{color: 'var(--primary-blue)'}}>90026960402</span></div>
                        </div>
                        <div className="image" style={{backgroundImage: `url(${img5x1000})`}}></div>
                    </div>
                    <div className="text-image-section">
                        <div className="headline">
                            <div className="title-small">Donazione libera</div>
                            <div className="subtitle">Fai un bonifico bancario indicando “donazione” per non pagare le commissioni sul trasferimento di denaro.
                            <br /><br />
    IBAN: <br /><span style={{display: 'block', color: 'var(--primary-blue)'}}>IT 95 I 053872390 1 000 00 1678 123</span></div>
                        </div>
                        <div className="image" style={{backgroundImage: `url(${imgLibera})`}}></div>
                    </div>
                    <div className="text-image-section">
                        <div className="headline">
                            <div className="title-small">Donazione di oggetti per il canile</div>
                            <div className="subtitle">Se hai coperte, ciotole, guinzagli e altri oggetti che non utilizzi, puoi portarli in canile.</div>
                        </div>
                        <div className="image" style={{backgroundImage: `url(${imgCanile})`}}></div>
                    </div>
                </div>
            </div>
            <div className="shop-section">

            </div>
        </div>
    )
}