import { useState } from 'react';

import AdoptionStatic from '../adoption-process-static/adoptionStatic';
import AboutUsCard from './aboutUsCard';

import './css/who-we-are.css';


import chiSiamoImage from './img/chi-siamo.jpg';
import volunteerImage from './img/volunteer.jpg';
import donateImage from './img/donation.jpg';
import operatorImage from './img/operator.jpg';
import directorImage from './img/director.jpg';
import adoptionImage from './img/adoption.jpg';

export default function WhoWeAre() {
    return (
        <div className="who-we-are">
            <div className="headline">
                <div className="title">Chi siamo</div>
                <div className="subtitle">
                Associazione Cesenate<br></br>Protezione Animali
                </div>
            </div>
            <div className="section-wrapper first">
                <div className="image" style={{backgroundImage: `url(${chiSiamoImage})`}}></div>
                <div className="title-small">ACPA promuove, disciplina, organizza attività di tutela dei diritti degli animali ed effettua interventi nel campo dei diritti dell'uomo e dell'ambiente.</div>
                <div className="card-associazione-wrapper">
                    <AboutUsCard image={volunteerImage} title="Diventa volontario" description={
                        <>
                        <div className='card-body'>Ci piace prenderci cura degli animali e creare nuove amicizie!</div>
                        <div className="text-spacing">Vieni al canile oppure scarica e compila il modulo</div>
                        <div className="text-spacing">Completa il nostro mini corso di addestramento</div>
                        <div className="text-spacing">Comunica la tua disponibilità e inizia!</div>
                        </>
                    } buttonText="leggi di più" buttonUrl="/contact" />
                    <AboutUsCard image={donateImage} title="Sostienici" description={
                        <>
                        <div className='card-body'>
                        Con il tuo aiuto, possiamo fare la differenza nella vita dei nostri amici a quattro zampe. 
                        <br /><br />
                        L'Associazione ACPA è un'organizzazione no-profit dedicata al benessere e alla cura dei cani bisognosi.
                        <br /><br />
                        Le tue donazioni ci consentono di garantire cibo, cure veterinarie e un rifugio sicuro per i nostri ospiti pelosi. Siamo grati per ogni contributo, grande o piccolo, che ci permette di continuare la nostra missione. 
                        </div>
                        </>
                    } buttonText="Fai una donazione" buttonUrl="/donate"/>
                </div>
            </div>
            <div className="section-wrapper second">
                <div className="headline">
                    <div className="title-small">La struttura</div>
                    <div className="subtitle">Qui scriviamo due righe per introdurre le 3 caratteristiche per cui la struttura è super figa.</div>
                </div>

                <div className="attributes-wrapper">
                    <div className="text-image-section">
                        <div className="headline">
                            <div className="title-small">Operatori</div>
                            <div className="subtitle">Il canile di Cesenate ha delle caratteristiche particolari. Questa è la descrizione di una di queste  caratteristiche.</div>
                        </div>
                        <div className="image" style={{backgroundImage: `url(${operatorImage})`}}></div>
                    </div>
                </div>
                <div className="attributes-wrapper">
                    <div className="text-image-section">
                        <div className="headline">
                            <div className="title-small">Direttore sanitario</div>
                            <div className="subtitle">Il canile di Cesenate ha delle caratteristiche particolari. Questa è la descrizione di una di queste  caratteristiche.</div>
                        </div>
                        <div className="image" style={{backgroundImage: `url(${directorImage})`}}></div>
                    </div>
                </div>
                <div className="attributes-wrapper">
                    <div className="text-image-section">
                        <div className="headline">
                            <div className="title-small">Adozione</div>
                            <div className="subtitle">Il canile di Cesenate ha delle caratteristiche particolari. Questa è la descrizione di una di queste  caratteristiche.</div>
                        </div>
                        <div className="image" style={{backgroundImage: `url(${adoptionImage})`}}></div>
                    </div>
                </div>
            </div>
            <div className="section-wrapper">
                <AdoptionStatic />
            </div>
        </div>
    )
}