import { format } from 'date-fns';
import { it } from 'date-fns/locale';

export default function EventCard({
    image,
    id,
    initDate,
    title,
    location
}) {

    // Transform into italian date with format SAB, 30 MAR alle 16:00
    const dayUppercase = format(new Date(initDate), "EEE", { locale: it }).toUpperCase();
    const dayNumber = format(new Date(initDate), "d");
    const monthUppercase = format(new Date(initDate), "MMM", { locale: it }).toUpperCase();
    const hour = format(new Date(initDate), "HH:mm");
    const humanDate = `${dayUppercase}, ${dayNumber} ${monthUppercase} alle ${hour}`

    const shortenTitle = title.length > 40 ? title.substring(0, 40) + '...' : title;

    return (
        <div className="card-event">
            <div className="image" style={{backgroundImage: `url(${image})`}}></div>
            <div className="elements-wrapper">
                <div className="headline">
                    <div className="date-and-time body-large">{humanDate}</div>
                    <div className="card-title">{shortenTitle}</div>
                    <div className="place card-body">{location}</div>
                </div>
                <div className="button-container">
                    <div className="button-card">
                        <a className="card-dark-default"href={`https://www.facebook.com/events/${id}`}>maggiori info</a>
                    </div>
                </div>
            </div>
        </div>
    )
}