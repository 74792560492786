import logo from "../img/logo/main-blue/azzurro-orizzontale.png";

import { Link } from "react-router-dom";

export default function Footer() {

    const scrollToTop = () => {
        const contentElement = document.getElementById("#content");
        contentElement.scrollIntoView({ 
            behavior: "smooth",
        });
    };

    return (
        <footer>
            <div className="contact-link-wrapper">
                <div className="headlines">
                    <div className="title">Domande?</div>
                    <div className="subtitle">Per ogni dubbio o domanda puoi contattarci oppure venire in canile negli orari di apertura</div>
                </div>
                <div className="button-link">
                    <Link to="/contact" onClick={scrollToTop}>Contatti</Link>
                </div>
            </div>
            <div className="nav-link-wrapper">
                <div className="logo">
                    <img src={logo} />
                </div>
                <div className="left">
                    <div className="nav-link">
                        <Link to="/adoptions" onClick={scrollToTop}>Adozioni</Link>
                        <Link to="/events" onClick={scrollToTop}>Eventi</Link>
                        <Link to="/advice" onClick={scrollToTop}>Consigli</Link>
                        <Link to="/who-we-are" onClick={scrollToTop}>Associazione</Link>
                        <Link to="/contact" onClick={scrollToTop}>Contatti</Link>
                        <Link to="/donate" onClick={scrollToTop}>Sostienici</Link>
                    </div>
                </div>
                <div className="right">
                    <div className="nav-link">
                        <Link to="/privacy" onClick={scrollToTop}>Privacy</Link>
                        <Link to="/cookies" onClick={scrollToTop}>Cookies</Link>
                        <Link to="/transparency" onClick={scrollToTop}>Transparenza</Link>
                        <Link to="/reviews" onClick={scrollToTop}>Recensione</Link>
                    </div>
                </div>
            </div>
            <div className="developer-link-wrapper">
                <div className="developer-links">
                    <a href="https://www.hanamisoftware.com/" target="_blank">Developed by HANAMI SOFTWARE</a>
                </div>
            </div>
        </footer>
    )
}