import { useEffect, useState } from 'react';
import axios from 'axios';

import EventCard from './eventCard';
import Loading from '../loading';

import './css/eventList.css';

export default function EventsList() {
    const [eventsList, setEventsList] = useState([]);
    useEffect(() => {
        axios.get(
            'https://www.acpacesena.org/api/v1/events'
        ).then((response) => {
            if (Array.isArray(response.data.data)) {
                let sanitizedEvents = [];
                for (let event of response.data.data) {
                    let eventToPush = {};
                    try {
                        eventToPush.id = event.id;
                        eventToPush.image = event.cover && event.cover.source ? String(event.cover.source) : 'https://i.imgur.com/3anaL09.jpeg';
                        eventToPush.title = event.name ? event.name : 'Eventi senza titolo';
                        eventToPush.location = event.place && event.place.name ? event.place.name : 'Eventi senza luogo';
                        eventToPush.initDate = event.start_time && event.start_time.date ? event.start_time.date : '';
                        sanitizedEvents.push(eventToPush);
                    } catch (error) {
                        console.error(`\n\nError parsing event from API.
                        \n\nThis is the error from the try block:\n\n${error}
                        \n\nThis is the element that caused the error:`, event);
                    }
                }
                setEventsList(sanitizedEvents);
                console.log(`Parsed correctly ${sanitizedEvents.length} events of ${response.data.data.length} from the API`);
            } else {
                console.error("Response data is not an array");
            }
        }).catch((error) => {
            console.error(error);
        });
    }, []);

    return (
        <div>
            <div className="headline">
                <div className="title">Eventi</div>
            </div>
            {eventsList.length === 0 && <Loading />}
            <div className="events-list">
                {eventsList.length > 0 && eventsList.map((event) => (
                    <EventCard
                            key={event.id}
                            image={event.image}
                            id={event.id}
                            initDate={event.initDate}
                            title={event.title}
                            location={event.location}
                    />
                ))}
            </div>
        </div>
    )
}