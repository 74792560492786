import './css/contact.css';
import './css/cardContact.css';

import facebookIcon from './img/facebook.png';
import instagramIcon from './img/instagram.png';

export default function Contact() {

    function getCurrentDomain() {
        // Return domain without folder
        return window.location.href.split('/').slice(0, 3).join('/')
    }
    const domain = getCurrentDomain();

    return (
        <div className="contact">
            <div className="headline">
                <div className="title">Contatti</div>
            </div>
            <div className="card-wrapper">
                <div className="card-contact">
                    <div className="card-title">Telefono</div>
                    <div className="elements-wrapper">
                        <div className="elements">
                            <div className="body-large">Giorni feriali dalle 08:00 alle 20:00</div>
                            <div className="subtitle">0547 27730 - 338 206 5977</div>
                        </div>
                        <div className="elements">
                            <div className="body-large">Giorni festivi dalle 08:00 alle 20:00</div>
                            <div className="subtitle">338 206 5977</div>
                        </div>
                        <div className="elements">
                            <div className="body-large">Notturno dalle 20:00 alle 08:00</div>
                            <div className="subtitle">112 - 113</div>
                        </div>
                    </div>
                </div>
                <div className="card-contact">
                    <div className="card-title">Orari</div>
                    <div className="elements-wrapper">
                        <div className="elements">
                            <div className="body-large">Primavera - Estate</div>
                            <div className="subtitle">14:30 - 18:30</div>
                        </div>
                        <div className="elements">
                            <div className="body-large">Autunno - Inverno</div>
                            <div className="subtitle">12:30 - 16:30</div>
                        </div>
                        <div className="elements">
                            <div className="body-large">Chiuso la domenica e nei giorni festivi.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-wrapper">
                <div className="card-contact email">
                    <div className="card-title">Mail</div>
                    <div className="elements-wrapper">
                        <div className="elements">
                            <div className="body-large">Inviateci un&apos;e-mail e vi risponderemo il prima possibile.</div>
                        </div>
                        <form className="contact-form" action="https://formsubmit.co/associazione-acpa@libero.it" method="POST">
                            <input type="hidden" name="_next" value={domain + "/email-sent"}></input>
                            <input type="hidden" name="_subject" value="Nuova e-mail dal sito web"></input>
                            <input type="text" name="name" required placeholder='Nome'/>
                            <input type="email" name="email" required placeholder='Email'/>
                            <textarea name="message" required placeholder='Messaggio'></textarea>
                            <button className="card-dark-default" type="submit">Invia il messaggio</button>
                        </form> 
                    </div>
                </div>
            </div>
            <div className="social-wrapper">
                <div className='headline'>
                    <div className="title">Social</div>
                    <div className="subtitle">Seguici anche sui social dove raccontiamo tante storie di adozioni e condividiamo un sacco di foto!</div>
                </div>
                <div className="buttons-wrapper">
                    <div className="button-link">
                        <a href="https://www.facebook.com/acpa.cesena/"><img src={facebookIcon} /></a>
                    </div>
                    <div className="button-link">
                        <a href="https://www.instagram.com/associazioneacpa/"><img src={instagramIcon} /></a>
                    </div>
                </div>
                <div style={{fontSize: '7px', textAlign: 'center'}}>Icons by <a href="https://www.flaticon.es/autores/pixel-perfect">Pixel Perfect</a></div>
            </div>
        </div>
    )
}