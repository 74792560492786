import './css/adoptionStatic.css';
import './css/accordion.css';
import './js/accordion.js';
import handleAccordionClick from './js/accordion.js';
import chevronImg from './img/chevron-up.svg';



export default function AdoptionStatic() {
    return (
        <div className="adoption-static">
            <div className="top">
                <div className="title">Processo di adozione</div>
                <div className="subtitle">Per adottare un cane, è importante seguire alcuni passi per assicurarci che la famiglia ed il cane siano compatibili.</div>
            </div>
            <div className="middle">
                <div className="accordion">
                    <div className="row" data-status="open">
                        <div className="header" onClick={handleAccordionClick}>
                            <div className="title-extra-small">1. Incontro conoscitivo</div>
                            <div className="chevron">
                                <img src={chevronImg}></img>
                            </div>
                        </div>
                        <div className="content body-large">
                            <ul>
                                <li>Quando arriverete in canile faremo assieme a voi un primo colloquio per conoscere il vostro stile di vita, le vostre esperienze e l’ambiente in cui il cane dovrà vivere.
    Abbiamo creato una mappatura, che consiste in un quiz di domande e ci consente di selezionare e presentarvi solo cani adatti alla vostra famiglia.</li>
                                <li>Quando arriverete in canile faremo assieme a voi un primo colloquio per conoscere il vostro stile di vita, le vostre esperienze e l’ambiente in cui il cane dovrà vivere.
    Abbiamo creato una mappatura, che consiste in un quiz di domande e ci consente di selezionare e presentarvi solo cani adatti alla vostra famiglia.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row" data-status="close">
                        <div className="header" onClick={handleAccordionClick}>
                            <div className="title-extra-small">2. Primo incontro con il cane</div>
                            <div className="chevron">
                                <img src={chevronImg}></img>
                            </div>
                        </div>
                        <div className="content body-large">
                            <ul>
                                <li>Al termine dell’incontro conoscitivo vi presenteremo una serie di cani idonei e vi racconteremo la loro storia, le loro caratteristiche e il loro trascorso con noi.
    Le prime interazioni con il cane avverranno in un recinto sicuro e sempre sotto le direttive e la supervisione di un operatore formato, che vi darà consigli su come avvicinavi e comunicare con lui nel modo migliore.</li>
                                <li>Al termine dell’incontro conoscitivo vi presenteremo una serie di cani idonei e vi racconteremo la loro storia, le loro caratteristiche e il loro trascorso con noi.
    Le prime interazioni con il cane avverranno in un recinto sicuro e sempre sotto le direttive e la supervisione di un operatore formato, che vi darà consigli su come avvicinavi e comunicare con lui nel modo migliore.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row" data-status="close">
                        <div className="header" onClick={handleAccordionClick}>
                            <div className="title-extra-small">3. Incontri successivi</div>
                            <div className="chevron">
                                <img src={chevronImg}></img>
                            </div>
                        </div>
                        <div className="content body-large">
                            <ul>
                                <li>Abbiamo protocollato un percorso di pre affido da svolgere in struttura, mirato sia a responsabilizzare il futuro proprietario sull’adozione, sia a formare i proprietari sulla corretta gestione del cane scelto ancor prima dell’adozione definitiva.
    Durante questo percorso imparerete come comunicare al meglio con il cane seguendo la sua psicologia, come condurlo al guinzaglio in maniera efficace anche in ambienti urbani e ad istruirlo nelle sue nuove abitudini e contesti.</li>
                                <li>Abbiamo protocollato un percorso di pre affido da svolgere in struttura, mirato sia a responsabilizzare il futuro proprietario sull’adozione, sia a formare i proprietari sulla corretta gestione del cane scelto ancor prima dell’adozione definitiva.
    Durante questo percorso imparerete come comunicare al meglio con il cane seguendo la sua psicologia, come condurlo al guinzaglio in maniera efficace anche in ambienti urbani e ad istruirlo nelle sue nuove abitudini e contesti.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row" data-status="close">
                        <div className="header" onClick={handleAccordionClick}>
                            <div className="title-extra-small">4. Valutazione finale</div>
                            <div className="chevron">
                                <img src={chevronImg}></img>
                            </div>
                        </div>
                        <div className="content body-large">
                            <ul>
                                <li>Tutto il percorso sarà documentato da un operatore che valuterà i progressi e vi aiuterà a creare un legame speciale.
Al termine del lavoro in struttura effettueremo uno o più incontri a domicilio mirati a gestire in modo corretto l’inserimento del cane nel nuovo ambiente, indirizzando e spiegando agli adottanti le basi di etologia e psicologia canina, le regole sociali e la gestione di spazi e risorse del cane.</li>
                                <li>Al termine del lavoro in struttura effettueremo uno o più incontri a domicilio mirati a gestire in modo corretto l’inserimento del cane nel nuovo ambiente, indirizzando e spiegando agli adottanti le basi di etologia e psicologia canina, le regole sociali e la gestione di spazi e risorse del cane.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="title-small">Ricorda</div>
                <div className="body-large">
                    <ul>
                        <li>Il percorso sarà adattato ai vostri tempi e disponibilità, più sarete disponibili e attenti e prima termineremo il nostro lavoro per voi.</li>
                        <li>Non spaventatevi. L’obiettivo è far uscire i nostri ospiti in adozione al meglio e il prima possibile. Adottare in canile è un azione meravigliosa ma fare un’adozione frettolosa potrebbe farti cambiare idea.</li>
                        <li>Adottare un cane è un impegno importante e richiede pazienza e responsabilità e perciò, i nostri operatori e istruttori saranno disponibili per consulenza e supporto sia pre che post adozione.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}