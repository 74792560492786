import loaderImg from '../img/loader.gif';

export default function Loading() {

    const loaderStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: "2rem",
        color: "var(--primary-red)",
    }
    
    return (
        <div className="loading">
            <div className="loader card-title" style={loaderStyle}>
                <img src={loaderImg} />
                Caricamento in corso...
            </div>
        </div>
    )
}