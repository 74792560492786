import { Routes, Route } from "react-router-dom";

import Footer from "./components/footer";
import Header from "./components/header";
import AdoptionList from "./components/adoption/adoptionList";
import EventsList from "./components/events/eventsList";
import Advice from "./components/advice/advice";
import WhoWeAre from "./components/who-we-are/who-we-are";
import Contact from "./components/contact/contact";
import Donate from "./components/donate/donate";
import Privacy from "./components/legal/Privacy";
import Cookies from "./components/legal/Cookies";

// Importing configuration needed to get albums
import facebookConfig from './facebook.config.json';


function App() {


  return (
      <div id="content">
        <Header />
        <Routes>
          <Route path="/" element={<AdoptionList adoptionAlbumId={facebookConfig.adoptionAlbumId}/>} />
          <Route path="/adoptions" element={<AdoptionList adoptionAlbumId={facebookConfig.adoptionAlbumId}/>} />
          <Route path="/events" element={<EventsList/>} />
          <Route path="/advice" element={<Advice/>} />
          <Route path="/who-we-are" element={<WhoWeAre/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/donate" element={<Donate/>} />
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="/cookies" element={<Cookies/>} />
          <Route path="/email-sent" element={<center style={{marginTop: "100px", color: "var(--primary-red)"}}><h1>Messaggio inviato, vi risponderemo al più presto :)</h1></center>} />
          <Route path="/*" element={<center style={{marginTop: "100px", color: "var(--primary-red)"}}><h1>404 Pagina non trovata</h1></center>} />
        </Routes>
        <Footer />
      </div>
  )
}

export default App
